import React from 'react';

import { LinkWrapper } from '@components';
import { Box, Button } from '@core';

const CTAButton = ({ title, link, buttonProps, py, backgroundColor }) => (
  <Box
    display="flex"
    justifyContent="center"
    width="100%"
    backgroundColor={backgroundColor}
    py={py ? py : 24}
  >
    <LinkWrapper link={link}>
      <Button type="button" {...buttonProps}>
        {title}
      </Button>
    </LinkWrapper>
  </Box>
);

export default CTAButton;
